import Cookies from "js-cookie";
import { authAPI } from "../api/authAPI";
import {
  setAuthData,
  setExpirationMessage,
  setExpirationWarnOpen,
  setIsAuth,
} from "../redux/slices/authSlice";

export const apiRequestWithRefresh = async (
  apiFunction,
  authData,
  dispatch,
  ...args
) => {
  let updatedAuthData = authData;

  try {
    const response = await apiFunction(authData, ...args);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        const newAuthData = await authAPI.refreshToken(authData);

        if (newAuthData && newAuthData.data.access) {
          updatedAuthData = newAuthData.data;

          Cookies.set("authData", JSON.stringify(updatedAuthData), {
            secure: true,
          });
          dispatch(setAuthData(updatedAuthData));
        }
      } catch (refreshError) {
        if (
          refreshError &&
          (refreshError.response.status === 401 ||
            refreshError.response.status === 400)
        ) {
          Cookies.remove("authData");
          dispatch(setIsAuth(false));
          dispatch(setExpirationWarnOpen(true));
          dispatch(
            setExpirationMessage(
              "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
            )
          );
          console.log("Failed to refresh token:", refreshError);
        }
        throw refreshError;
      }
    } else {
      console.log(error);
      let errorMessages = [];
      const messages = error.response.data;
      Object.values(messages).forEach((item) => errorMessages.push(item));
      throw errorMessages.join(" ");
    }
  }
  try {
    const response = await apiFunction(updatedAuthData, ...args);
    return response;
  } catch (apiFunctionError) {
    console.log("Error during retry of apiFunction:", apiFunctionError);
    let errorMessages = [];
    const messages = apiFunctionError.response.data;
    Object.values(messages).forEach((item) => errorMessages.push(item));
    throw errorMessages.join(" ");
  }
};
