import styles from "./Request.module.scss";
import React, { useEffect, useState } from "react";
import ReusableChip from "../../components/ReusableChip/ReusableChip";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FaCloudDownloadAlt } from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import { GrAttachment } from "react-icons/gr";
import { getFileIcon } from "../../services/getFileIcon";
import DotsSpinner from "../Loaders/DotsSpinner/DotsSpinner";
import { getRequestStatusStyles } from "../../services/getRequestStatusStyles";
import { requestAPI } from "../../api/requestAPI";
import { useSelector } from "react-redux";
import { serverURL } from "../../config";

const Request = ({
  item,
  headers,
  contract,
  currencies,
  handleFileUpload,
  triggerFileInput,
  requestLoading,
  handleChangeUpdateInputs,
  handleUpdateRequest,
  statusConfig,
  requestDocTypes,
  fileLoadingStatus,
  deleteFile,
  fileDeletionStatus,
}) => {
  const [reqTemplate, setReqTemplate] = useState(null);
  const [otherFiles, setOtherFiles] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);

  const authData = useSelector((state) => state.auth.authData);

  useEffect(() => {
    const filteredFiles = item.files.filter((file) => file.doc_type === 10);
    const filteredInvoice = item.files.filter((file) => file.doc_type === 4);

    if (filteredFiles?.length > 0) setOtherFiles(filteredFiles);
    else if (filteredFiles?.length === 0) setOtherFiles([]);

    if (filteredInvoice?.length > 0) setInvoiceFiles(filteredInvoice);
    else if (filteredInvoice?.length === 0) setInvoiceFiles([]);
  }, [item]);

  useEffect(() => {
    console.log("currencies", currencies);
    console.log("item", item);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (item.request_status === 2) {
      const fetchTemplate = async () => {
        try {
          const response = await requestAPI.get_req_template(authData);
          if (response) {
            if (response.statusText === "OK") {
              setReqTemplate(response.data);
            }
          }
        } catch (err) {
          console.log(err);
        }
      };

      fetchTemplate();
    }
  }, [item, authData]);

  const hasDocType8 = item.files.some((file) => file.doc_type === 8);
  const hasDocType9 = item.files.some((file) => file.doc_type === 9);

  const renderField = (header) => {
    const value = item[header.name];

    if (header.type === "Text") {
      if (value === null || value === undefined || value.length === 0) {
        return <div className={styles.field}>---</div>;
      }
    }

    switch (header.type) {
      case "Chip":
        return (() => {
          const styles = getRequestStatusStyles(item.request_status);
          if (!styles) return null;
          return (
            <ReusableChip
              borderColor={styles.borderColor}
              backgroundColor={styles.backgroundColor}
              color={styles.color}
              label={item.status}
              minHeight="45px"
              minWidth="100px"
            />
          );
        })();

      case "Select":
        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Валюта</InputLabel>
            <Select
              value={value || ""}
              onChange={(e) => handleChangeUpdateInputs(e, header.name)}
              label="Валюта"
              disabled={statusConfig[item.request_status].disabled.some(
                (item) => item === header.name
              )}
            >
              {currencies &&
                currencies.length > 0 &&
                currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id || ""}>
                    {currency.currency_title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );

      case "TextFieldNumber":
        return (
          <TextField
            value={value || ""}
            type="number"
            label={header.text}
            onChange={(e) => handleChangeUpdateInputs(e, header.name)}
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                color: "#757575",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#757575",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#757575",
                },
              },
            }}
            disabled={statusConfig[item.request_status].disabled.some(
              (item) => item === header.name
            )}
          />
        );
      case "TextField":
        return (
          <TextField
            value={value || ""}
            label={header.text}
            onChange={(e) => handleChangeUpdateInputs(e, header.name)}
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                color: "#757575",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#757575",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#757575",
                },
              },
            }}
            disabled={statusConfig[item.request_status].disabled.some(
              (item) => item === header.name
            )}
          />
        );

      case "Text":
      default:
        return <div className={styles.field}>{value}</div>;
    }
  };
  return (
    <div className={styles.container}>
      <h2>Заявка</h2>
      {statusConfig[item.request_status].text.length > 0 && (
        <p className={styles.warn}>{statusConfig[item.request_status].text}</p>
      )}
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Контракт</span>
          </div>
          <div className={styles.field}>{contract.contract_number}</div>
        </div>
        <div className={styles.line} />
      </>

      {headers.map((header, index) => (
        <React.Fragment key={index}>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{header.label}</span>
            </div>
            <div className={styles.field}>{renderField(header)}</div>
          </div>
          <div className={styles.line} />
        </React.Fragment>
      ))}

      {reqTemplate && item.request_status === 2 && (
        <>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{reqTemplate.file_name} </span>
            </div>
            <div className={styles.filesContainer}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.icon}>
                    {getFileIcon(reqTemplate.file_type)}
                  </div>
                  <span className={styles.text}>{reqTemplate.file_name}</span>
                </div>
                <button className={styles.downloadButton}>
                  <a
                    href={`https://shev-taras.com${reqTemplate.temlate_file}`}
                    download={reqTemplate.file_name}
                    rel="noreferrer"
                    className={styles.downloadLink}
                  >
                    <FaCloudDownloadAlt size={20} />
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.line} />
        </>
      )}

      {item.files.length > 0 && (
        <>
          {item.files.map(
            (item, index) =>
              item.doc_type !== 10 &&
              item.doc_type !== 4 && (
                <React.Fragment key={index}>
                  <div className={styles.element}>
                    <div className={styles.name}>
                      <span>
                        {requestDocTypes.find(
                          (type) => type.id === item.doc_type
                        )?.type_title || "Файлы"}
                      </span>
                    </div>
                    <div className={styles.filesContainer}>
                      <FilesCard
                        item={item}
                        getFileIcon={getFileIcon}
                        deleteFile={deleteFile}
                        fileDeletionStatus={fileDeletionStatus}
                      />
                    </div>
                  </div>
                  <div className={styles.line} />
                </React.Fragment>
              )
          )}
          {invoiceFiles?.length > 0 && (
            <>
              <div className={styles.element}>
                <div className={styles.name}>
                  <span>Инвойс от поставщика</span>
                </div>
                <div className={styles.filesContainer}>
                  {invoiceFiles?.map((item, index) => (
                    <React.Fragment key={index}>
                      <FilesCard
                        item={item}
                        getFileIcon={getFileIcon}
                        deleteFile={deleteFile}
                        fileDeletionStatus={fileDeletionStatus}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className={styles.line} />
            </>
          )}

          {otherFiles?.length > 0 && (
            <>
              <div className={styles.element}>
                <div className={styles.name}>
                  <span>Другое</span>
                </div>
                <div className={styles.filesContainer}>
                  {otherFiles?.map((item, index) => (
                    <React.Fragment key={index}>
                      <FilesCard
                        item={item}
                        getFileIcon={getFileIcon}
                        deleteFile={deleteFile}
                        fileDeletionStatus={fileDeletionStatus}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className={styles.line} />
            </>
          )}
        </>
      )}

      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Другие файлы</span>
          </div>
          <div className={styles.field}>
            <UploadButton
              triggerFileInput={triggerFileInput}
              item={item}
              fileLoading={fileLoadingStatus.otherFiles}
              inputId="choose-file"
              text="Выберите другие файлы"
              disabled={false}
            />
            <input
              id="choose-file"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e, 10)}
            />
          </div>
        </div>
      </>

      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Инвойс от поставщика </span>
          </div>
          <div className={styles.field}>
            <UploadButton
              triggerFileInput={triggerFileInput}
              item={item}
              fileLoading={fileLoadingStatus.invoiceFiles}
              inputId="choose-invoice"
              text="Выберите инвойс"
              disabled={false}
            />
            <input
              id="choose-invoice"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e, 4)}
            />
          </div>
        </div>
      </>

      {item.request_status === 2 && (
        <>
          <>
            <div className={styles.element}>
              <div className={styles.name}>
                <span>Заявка в Word</span>
              </div>
              <div className={styles.field}>
                <UploadButton
                  triggerFileInput={triggerFileInput}
                  item={item}
                  fileLoading={fileLoadingStatus.wordFile}
                  inputId="choose-word"
                  text="Выберите заявку в Word"
                  disabled={hasDocType9}
                />
                <input
                  id="choose-word"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload(e, 9)}
                />
              </div>
            </div>
          </>
          <>
            <div className={styles.element}>
              <div className={styles.name}>
                <span>Заявка в PDF</span>
              </div>
              <div className={styles.field}>
                <UploadButton
                  triggerFileInput={triggerFileInput}
                  item={item}
                  fileLoading={fileLoadingStatus.pdfFile}
                  inputId="choose-pdf"
                  text="Выберите заявку в PDF"
                  disabled={hasDocType8}
                />
                <input
                  id="choose-pdf"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload(e, 8)}
                />
              </div>
            </div>
          </>
        </>
      )}

      {item && (
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(28, 107, 31, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(28, 107, 31, 0.8)",
              },
            }}
            disabled={
              item.request_status === 10 ||
              item.request_status === 12 ||
              (item.request_status === 2 && !(hasDocType8 && hasDocType9))
            }
            onClick={handleUpdateRequest}
          >
            {requestLoading ? (
              <DotsSpinner />
            ) : item.request_status === 2 ? (
              "Отправить заявку"
            ) : (
              "Обновить"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

const UploadButton = ({
  triggerFileInput,
  item,
  fileLoading,
  inputId,
  text,
  disabled,
}) => {
  return (
    <button
      className={
        item.request_status === 10 || item.request_status === 12 || disabled
          ? styles.buttonDisabled
          : styles.uploadButton
      }
      onClick={() => triggerFileInput(inputId)}
      disabled={
        item.request_status === 10 || item.request_status === 12 || disabled
      }
    >
      {fileLoading ? (
        <DotsSpinner />
      ) : (
        <>
          <span>{text}</span>
          <GrAttachment size={20} />
        </>
      )}
    </button>
  );
};

const FilesCard = ({ item, getFileIcon, deleteFile, fileDeletionStatus }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.icon}>{getFileIcon(item.file_type)}</div>
        <span className={styles.text}>{item.file_name}</span>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.downloadButton}>
          <a
            href={`${serverURL}${item.req_file}`}
            download={item.file_name}
            target={
              [
                "application/pdf",
                "image/jpeg",
                "image/png",
                "image/svg+xml",
                "image/heic",
                "image/heif",
              ].includes(item.file_type)
                ? "_blank"
                : "_self"
            }
            rel="noreferrer"
            className={styles.downloadLink}
          >
            <FaCloudDownloadAlt size={20} />
          </a>
        </button>
        <button
          className={styles.deleteButton}
          onClick={() => deleteFile(item.id, item.doc_type)}
        >
          {fileDeletionStatus[item.doc_type] ? (
            <DotsSpinner />
          ) : (
            <Tooltip title="Удалить файл" className={styles.downloadLink}>
              <DeleteIcon className={styles.icon} />
            </Tooltip>
          )}
        </button>
      </div>
    </div>
  );
};

export default Request;
