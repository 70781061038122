import styles from "./AllRequestsPage.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import { allHeaders } from "../../data/requestsPageOptions";
import {
  setAuthData,
  setExpirationMessage,
  setExpirationWarnOpen,
  setIsAuth,
} from "../../redux/slices/authSlice";
import Cookies from "js-cookie";
import { authAPI } from "../../api/authAPI";
import { requestAPI } from "../../api/requestAPI";
import moment from "moment";
import AllRequestsTable from "../../components/AllRequestsTable/AllRequestsTable";
import { contractAPI } from "../../api/contractAPI";
import ModalsContext from "../../contexts/ModalsContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Tooltip } from "@mui/material";
import { redirectURL } from "../../config";
// import { setContractId } from "../../redux/slices/contractSlice";
// import { setCompanyId } from "../../redux/slices/agentSlice";

const initFilters = {
  companyName: "",
  contractName: "",
  status: "",
  currency: "",
  request_date: null,
  total: "",
  curs: "",
};

const AllRequestsPage = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [allRequests, setAllRequests] = useState([]);
  const [filters, setFilters] = useState(initFilters);
  const [sortOrder, setSortOrder] = useState("desc");

  const [headers, setHeaders] = useState(
    allHeaders.map((header) => ({ ...header, filterIsOpen: false }))
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const authData = useSelector((state) => state.auth.authData);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const company = useSelector((state) => state.agent.company);
  const profiles = useSelector((state) => state.agent.profiles);
  const contract = useSelector((state) => state.contract.contract);

  const dispatch = useDispatch();

  const { setNavbarDisabled } = useContext(ModalsContext);

  // Fetching requests and request-related data
  useEffect(() => {
    if (isAuth && company) {
      const fetchAllRequests = async (newAuthData = null) => {
        try {
          setNavbarDisabled(true);
          let statuses;
          let currencies;
          let allContracts;

          const dataCurrency = await requestAPI.get__currency(
            newAuthData || authData
          );
          if (dataCurrency.statusText === "OK") {
            const pickedCurrencies = ["RUB", "USD", "EUR", "CNY"];
            currencies = dataCurrency.data.filter((currency) =>
              pickedCurrencies.includes(currency.currency_title)
            );
          }

          const dataStatuses = await requestAPI.get__request_statuses(
            newAuthData || authData
          );
          if (dataStatuses.statusText === "OK") {
            statuses = dataStatuses.data;
          }

          const responseContracts = await contractAPI.get_contracts(
            newAuthData || authData
          );
          if (responseContracts.statusText === "OK") {
            allContracts = responseContracts.data;
          }

          const response = await requestAPI.get_requests(
            newAuthData || authData
          );
          if (response.statusText === "OK") {
            const requestsData = response.data.map((request) =>
              updateRequest(request, statuses, currencies, allContracts)
            );
            requestsData.sort((a, b) => a.number_request - b.number_request);
            console.log("requestsData", requestsData);
            setAllRequests(requestsData);
          }
          setPageLoading(false);
          setNavbarDisabled(false);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            try {
              const newAuthData = await authAPI.refreshToken(authData);
              if (newAuthData && newAuthData.data.access) {
                const updatedAuthData = newAuthData.data;
                Cookies.set("authData", JSON.stringify(updatedAuthData), {
                  secure: true,
                });
                dispatch(setAuthData(updatedAuthData));
                fetchAllRequests(updatedAuthData);
              }
            } catch (refreshError) {
              if (
                refreshError &&
                (refreshError.response.status === 401 ||
                  refreshError.response.status === 400)
              ) {
                console.log("Error refreshing token:", refreshError);
                Cookies.remove("authData");
                dispatch(setIsAuth(false));
                dispatch(setExpirationWarnOpen(true));
                dispatch(
                  setExpirationMessage(
                    "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
                  )
                );
                setPageLoading(false);
                setNavbarDisabled(false);
              }
            }
          } else {
            console.error("Error fetching requests:", err);
            setPageLoading(false);
            setNavbarDisabled(false);
          }
        }
      };
      fetchAllRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, company, contract]);

  const deleteFilters = () => {
    setFilters(initFilters);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFilterOpen = (isOpen, headerName, event) => {
    setHeaders((prevHeaders) =>
      prevHeaders.map((header) =>
        header.name === headerName
          ? { ...header, filterIsOpen: isOpen }
          : { ...header }
      )
    );
    setAnchorEl(isOpen ? event.currentTarget : null);
  };

  const filteredRows = allRequests.filter((row) => {
    return (
      (filters.companyName
        ? row.companyName.includes(filters.companyName)
        : true) &&
      (filters.contractName
        ? row.contractName.includes(filters.contractName)
        : true) &&
      (filters.status ? row.status.includes(filters.status) : true) &&
      (filters.currency
        ? String(row.currency).includes(filters.currency)
        : true) &&
      (filters.request_date
        ? row.request_date === filters.request_date.format("DD.MM.YYYY")
        : true) &&
      (filters.total ? String(row.total).includes(filters.total) : true) &&
      (filters.curs ? String(row.curs).includes(filters.curs) : true)
    );
  });

  const sortRequests = () => {
    setAllRequests((prevRequests) => {
      const sortedRequests = [...prevRequests].sort((a, b) => {
        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split(".");
          return new Date(`${year}-${month}-${day}`);
        };
        if (sortOrder === "asc") {
          return parseDate(a.request_date) - parseDate(b.request_date); // Возрастание
        } else {
          return parseDate(b.request_date) - parseDate(a.request_date); // Убывание
        }
      });
      return sortedRequests;
    });

    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const updateRequest = (request, statuses, currencies, contracts) => {
    const foundStatus = statuses?.find(
      (status) => status.id === request.request_status
    );
    const foundCurrency = currencies?.find(
      (currency) => currency.id === request.currency_pay
    );
    const foundCompanyName = profiles?.find(
      (profile) => profile.id === request.agent
    );
    const foundContractName = contracts?.find(
      (contract) => contract.id === request.number_contract
    );

    return {
      ...request,
      status: foundStatus?.status_title,
      currency: foundCurrency?.currency_title,
      request_date: moment(request.request_date).format("DD.MM.YYYY"),
      total: request.total && parseInt(request.total, 10),
      companyName: foundCompanyName?.full_name,
      contractName: foundContractName?.contract_number,
    };
  };

  const handleRowClick = async (row) => {
    const { agent, number_contract, id } = row;
    if (agent && number_contract && id) {
      const url = `${redirectURL}/requests/?agent_id=${agent}&contract_id=${number_contract}&req_id=${id}`;
      window.location.href = url;
    }
  };

  return (
    <div className={styles.container}>
      {pageLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="#6367b1d4"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        <>
          {Object.values(filters).some((value) => value) && (
            <div className={styles.headerContainer}>
              <div className={styles.headerText}>
                {Object.keys(filters).map((item, index) => {
                  const header = headers.find((header) => header.name === item);
                  return (
                    header &&
                    filters[item] && (
                      <React.Fragment key={index}>
                        <div className={styles.label}>{header.label}:</div>
                        <div className={styles.text}>
                          {item !== "request_date"
                            ? filters[item]
                            : filters[item].format("DD.MM.YYYY")}
                        </div>
                      </React.Fragment>
                    )
                  );
                })}
              </div>
              <Tooltip
                title="Удалить фильтры"
                className={styles.iconDelete}
                onClick={deleteFilters}
              >
                <DeleteForeverIcon className={styles.icon} />
              </Tooltip>
            </div>
          )}
          {allRequests.length > 0 && (
            <AllRequestsTable
              headers={headers}
              rows={allRequests}
              handleFilterChange={handleFilterChange}
              filteredRows={filteredRows}
              filters={filters}
              handleFilterOpen={handleFilterOpen}
              anchorEl={anchorEl}
              handleRowClick={handleRowClick}
              sortRequests={sortRequests}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AllRequestsPage;
