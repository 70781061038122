import RequestsTable from "../../components/RequestsTable/RequestsTable";
import styles from "./RequestsPage.module.scss";
import { useContext, useEffect, useState } from "react";
import Request from "../../components/Request/Request";
import MainModal from "../../components/MainModal/MainModal";
import { IoMdClose } from "react-icons/io";
import { Button, Divider } from "@mui/material";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { requestAPI } from "../../api/requestAPI";
import {
  addRequests,
  setCurrencies,
  setRequestDocTypes,
  setRequests,
  setRequestsStatuses,
  updateRequests,
} from "../../redux/slices/requestSlice";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalsContext from "../../contexts/ModalsContext";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import { ThreeCircles } from "react-loader-spinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import SnackbarSuccess from "../../reusables/SnackbarSuccess";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import SnackbarFail from "../../reusables/SnackbarFail";
import {
  headers,
  newRequestOptions,
  requestOptions,
} from "../../data/requestsPageOptions";
import { statusConfig } from "../../data/requestsConfig";
import { apiRequestWithRefresh } from "../../services/apiRequestWithRefresh";
import {
  setAuthData,
  setExpirationMessage,
  setExpirationWarnOpen,
  setIsAuth,
} from "../../redux/slices/authSlice";
import Cookies from "js-cookie";
import { authAPI } from "../../api/authAPI";

const RequestsPage = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);

  const [requestLoading, setRequestLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [loadingNew, setLoadingNew] = useState(false);

  const [newRequestData, setNewRequestData] = useState({
    request_status: 1,
    comment_client: "",
    currency_pay: null,
  });

  const [fileLoadingStatus, setFileLoadingStatus] = useState({
    otherFiles: false,
    invoiceFiles: false,
    wordFile: false,
    pdfFile: false,
  });

  const [fileDeletionStatus, setFileDeletionStatus] = useState({
    4: false,
    10: false,
    9: false,
    8: false,
  });

  const contract = useSelector((state) => state.contract.contract);
  const authData = useSelector((state) => state.auth.authData);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const requests = useSelector((state) => state.request.requests);
  const requestStatuses = useSelector((state) => state.request.requestStatuses);
  const currencies = useSelector((state) => state.request.currencies);
  const requestDocTypes = useSelector((state) => state.request.requestDocTypes);
  const contractStatus = useSelector((state) => state.contract.contractStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleRequesttModalOpen,
    handleRequestModalClose,
    newRequestModalOpen,
    setNavbarDisabled,
  } = useContext(ModalsContext);

  const [searchParams] = useSearchParams();
  const req_id = searchParams.get("req_id");

  useEffect(() => {
    if (
      req_id &&
      contract &&
      currencies?.length > 0 &&
      requestStatuses?.length > 0
    ) {
      const fetchRequest = async (newAuthData = null) => {
        try {
          setNavbarDisabled(true);
          const response = await requestAPI.get_request(
            newAuthData || authData,
            contract.id,
            parseInt(req_id)
          );
          if (response) {
            if (response.statusText === "OK") {
              const requestData = response.data;
              const updatedNewRequest = updateRequest(
                requestData,
                requestStatuses,
                currencies
              );
              setSelectedRequest(updatedNewRequest);
            }
          }
          setPageLoading(false);
          setNavbarDisabled(false);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            try {
              const newAuthData = await authAPI.refreshToken(authData);
              if (newAuthData && newAuthData.data.access) {
                const updatedAuthData = newAuthData.data;
                Cookies.set("authData", JSON.stringify(updatedAuthData), {
                  secure: true,
                });
                dispatch(setAuthData(updatedAuthData));
                fetchRequest();
              }
            } catch (refreshError) {
              if (
                refreshError &&
                (refreshError.response.status === 401 ||
                  refreshError.response.status === 400)
              ) {
                console.log("Error refreshing token:", refreshError);
                Cookies.remove("authData");
                dispatch(setIsAuth(false));
                dispatch(setExpirationWarnOpen(true));
                dispatch(
                  setExpirationMessage(
                    "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
                  )
                );
                setPageLoading(false);
                setNavbarDisabled(false);
              }
            }
          } else {
            console.log(err);
            setPageLoading(false);
            setNavbarDisabled(false);
          }
        }
      };
      fetchRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req_id, contract, currencies, requestStatuses, dispatch]);

  // Fetching requests and request-related data
  useEffect(() => {
    if (isAuth && contract) {
      const fetchRequests = async (newAuthData = null) => {
        try {
          setNavbarDisabled(true);
          let statuses;
          let currencies;

          const dataCurrency = await requestAPI.get__currency(
            newAuthData || authData
          );
          if (dataCurrency.statusText === "OK") {
            const pickedCurrencies = ["RUB", "USD", "EUR", "CNY"];
            currencies = dataCurrency.data.filter((currency) =>
              pickedCurrencies.includes(currency.currency_title)
            );
            dispatch(setCurrencies(currencies));
          }

          const dataStatuses = await requestAPI.get__request_statuses(
            newAuthData || authData
          );
          if (dataStatuses.statusText === "OK") {
            statuses = dataStatuses.data;
            dispatch(setRequestsStatuses(statuses));
          }

          const response = await requestAPI.get_request(
            newAuthData || authData,
            contract.id
          );
          if (response.statusText === "OK") {
            if (response.data.length === 0) {
              handleRequesttModalOpen();
            } else if (response.data.length > 0 && newRequestModalOpen) {
              handleRequestModalClose();
            }
            const requestsData = response.data.map((request) =>
              updateRequest(request, statuses, currencies)
            );
            requestsData.sort((a, b) => a.number_request - b.number_request);

            dispatch(setRequests(requestsData));
          }
          const responseDocTypes = await requestAPI.get__request_doc_types(
            newAuthData || authData
          );
          if (responseDocTypes.statusText === "OK") {
            dispatch(setRequestDocTypes(responseDocTypes.data));
          }
          setPageLoading(false);
          setNavbarDisabled(false);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            try {
              const newAuthData = await authAPI.refreshToken(authData);
              if (newAuthData && newAuthData.data.access) {
                const updatedAuthData = newAuthData.data;
                Cookies.set("authData", JSON.stringify(updatedAuthData), {
                  secure: true,
                });
                dispatch(setAuthData(updatedAuthData));
                fetchRequests(updatedAuthData);
              }
            } catch (refreshError) {
              if (
                refreshError &&
                (refreshError.response.status === 401 ||
                  refreshError.response.status === 400)
              ) {
                console.log("Error refreshing token:", refreshError);
                Cookies.remove("authData");
                dispatch(setIsAuth(false));
                dispatch(setExpirationWarnOpen(true));
                dispatch(
                  setExpirationMessage(
                    "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
                  )
                );
                setPageLoading(false);
                setNavbarDisabled(false);
              }
            }
          } else {
            console.error("Error fetching requests:", err);
            setPageLoading(false);
            setNavbarDisabled(false);
          }
        }
      };
      fetchRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, contract, dispatch]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSuccess = () => {
    setSnackbarSuccessOpen(false);
  };

  const handleCloseFail = () => {
    setSnackbarFailOpen(false);
  };

  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  const updateRequest = (request, statuses, currencies) => {
    const foundStatus = statuses.find(
      (status) => status.id === request.request_status
    );
    const foundCurrency = currencies.find(
      (currency) => currency.id === request.currency_pay
    );
    return {
      ...request,
      status: foundStatus.status_title,
      currency: foundCurrency?.currency_title,
      request_date: moment(request.request_date).format("DD.MM.YYYY"),
      total: request.total && parseInt(request.total, 10),
    };
  };

  const goBackToRequests = () => {
    setSelectedRequest(null);
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };

  const handleFileUpload = async (event, docType) => {
    const file = event.target.files[0];

    const formData = new FormData();

    if (file) {
      let fileKey;
      switch (docType) {
        case 10:
          fileKey = "otherFiles";
          break;
        case 4:
          fileKey = "invoiceFiles";
          break;
        case 9:
          fileKey = "wordFile";
          break;
        case 8:
          fileKey = "pdfFile";
          break;
        default:
          return;
      }

      setFileLoadingStatus((prev) => ({
        ...prev,
        [fileKey]: true,
      }));
      setNavbarDisabled(true);

      if (file.size > 50000000) {
        setSnackbarFailOpen(true);
        setSnackbarText("Размер файла превышает 50 МБ");
        setNavbarDisabled(false);

        setFileLoadingStatus((prev) => ({
          ...prev,
          [fileKey]: false,
        }));
        return;
      }
      try {
        formData.append("req_file", file);
        formData.append("request", selectedRequest.id);
        formData.append("file_name", file.name);
        formData.append("file_type", file.type);
        formData.append("doc_type", docType);

        const response = await apiRequestWithRefresh(
          requestAPI.postfile,
          authData,
          dispatch,
          formData
        );
        if (response.statusText === "Created") {
          const updatedRequest = {
            ...selectedRequest,
            files: [...selectedRequest.files, response.data],
          };
          setSelectedRequest(updatedRequest);
        }
        setNavbarDisabled(false);

        setFileLoadingStatus((prev) => ({
          ...prev,
          [fileKey]: false,
        }));
      } catch (errorMessages) {
        console.log("Ошибка при загрузке файла:", errorMessages);
        setSnackbarFailOpen(true);
        setSnackbarText(`Ошибка при загрузке файла: ${errorMessages}`);
        setNavbarDisabled(false);

        setFileLoadingStatus((prev) => ({
          ...prev,
          [fileKey]: false,
        }));
      }
    }
  };

  const handleChangeInputs = (event, field) => {
    let value = event.target.value;

    setNewRequestData({
      ...newRequestData,
      [field]: value,
    });
  };

  const handleChangeUpdateInputs = (event, field) => {
    let value = event.target.value;

    setSelectedRequest({
      ...selectedRequest,
      [field]: value,
    });
  };

  const handleRowClick = async (row) => {
    console.log("row", row);

    try {
      setPageLoading(true);
      setNavbarDisabled(true);

      const response = await apiRequestWithRefresh(
        requestAPI.get_request,
        authData,
        dispatch,
        contract.id,
        row.id
      );
      if (response.statusText === "OK") {
        const requestData = response.data;
        const updatedNewRequest = updateRequest(
          requestData,
          requestStatuses,
          currencies
        );
        setSelectedRequest(updatedNewRequest);
      }
      setPageLoading(false);
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setPageLoading(false);
      setNavbarDisabled(false);
    }
  };

  const deleteFile = async (file_id, docType) => {
    setFileDeletionStatus((prev) => ({
      ...prev,
      [docType]: true,
    }));
    setNavbarDisabled(true);
    try {
      const data = await apiRequestWithRefresh(
        requestAPI.delete_req_file,
        authData,
        dispatch,
        file_id
      );
      if (data.statusText === "OK") {
        const updatedRequest = {
          ...selectedRequest,
          files: selectedRequest.files.filter((file) => file.id !== file_id),
        };
        setSelectedRequest(updatedRequest);
      }
      setSnackbarSuccessOpen(true);
      setFileDeletionStatus((prev) => ({
        ...prev,
        [docType]: false,
      }));
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setFileDeletionStatus((prev) => ({
        ...prev,
        [docType]: false,
      }));
      setNavbarDisabled(false);
    }
  };

  const handleCreateRequest = async () => {
    const dataToDB = {
      ...newRequestData,
      number_contract: contract.id,
    };

    if (!newRequestData.currency_pay) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, укажите валюту");
      return;
    }
    try {
      setLoadingNew(true);
      setNavbarDisabled(true);

      const data = await apiRequestWithRefresh(
        requestAPI.create_request,
        authData,
        dispatch,
        dataToDB
      );

      if (data.statusText === "Created") {
        const newRequest = data.data;
        const updatedNewRequest = updateRequest(
          newRequest,
          requestStatuses,
          currencies
        );
        dispatch(addRequests(updatedNewRequest));
        handleRequestModalClose();
      }
      setLoadingNew(false);
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setLoadingNew(false);
      setNavbarDisabled(false);
    }
  };

  const handleUpdateRequest = async () => {
    const config = statusConfig[selectedRequest.request_status];

    if (selectedRequest.request_status === 2 && !selectedRequest.total) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, укажите сумму");
      return;
    }

    const dataToDB = {
      request_status: config.nextStatus,
      comment_client: selectedRequest.comment_client,
      total: selectedRequest.total,
      currency_pay: selectedRequest.currency_pay,
    };

    try {
      setRequestLoading(true);
      setNavbarDisabled(true);

      const data = await apiRequestWithRefresh(
        requestAPI.update__request,
        authData,
        dispatch,
        dataToDB,
        selectedRequest.id
      );

      if (data.statusText === "OK") {
        const updatedRequest = data.data;
        const updatedNewRequest = updateRequest(
          updatedRequest,
          requestStatuses,
          currencies
        );
        setSelectedRequest(updatedNewRequest);
        dispatch(updateRequests(updatedNewRequest));
      }
      setSnackbarSuccessOpen(true);
      setRequestLoading(false);
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setRequestLoading(false);
      setNavbarDisabled(false);
    }
  };

  if (contractStatus) {
    if (contractStatus.id !== 7 && contractStatus.id !== 5) {
      return <Navigate to="/contracts" />;
    }
  }

  return (
    <div className={styles.container}>
      {!selectedRequest && contract && (
        <div className={styles.headerText}>
          <div className={styles.text}>Плательщик</div>
          <div className={styles.payer}>{contract.agent_title}</div>
          <div className={styles.text}>Контракт</div>
          <div className={styles.payer}>{contract.contract_number}</div>
        </div>
      )}

      <SnackbarSuccess
        snackbarOpen={snackbarSuccessOpen}
        handleClose={handleCloseSuccess}
        snackbarText="Заявка обновлена"
      />
      <SnackbarFail
        snackbarOpen={snackbarFailOpen}
        handleClose={handleCloseFail}
        snackbarText={snackbarText}
      />

      <div className={styles.buttonContainer}>
        {selectedRequest && (
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              // minWidth: "140px",
              minHeight: "40px",
              color: "rgba(28, 107, 31, 0.7)",
              borderColor: "rgba(28, 107, 31, 0.7)",
              "&:hover": {
                color: "rgba(28, 107, 31, 0.8)",
                borderColor: "rgba(28, 107, 31, 0.7)",
              },
            }}
            onClick={goBackToRequests}
          >
            Вернуться к заявкам
          </Button>
        )}
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{
            // minWidth: "140px",
            minHeight: "40px",
            backgroundColor: selectedRequest ? "rgba(28, 107, 31, 0.7)" : null,
            "&:hover": {
              backgroundColor: selectedRequest
                ? "rgba(28, 107, 31, 0.8)"
                : null,
            },
          }}
          onClick={handleRequesttModalOpen}
          disabled={pageLoading}
        >
          Новая заявка
        </Button>
      </div>

      {pageLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(33, 150, 243, 0.9)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        requests.length > 0 &&
        !selectedRequest && (
          <RequestsTable
            headers={headers}
            contract={contract}
            payer={contract.agent_title}
            rows={requests}
            handleRowClick={handleRowClick}
          />
        )
      )}
      {!selectedRequest && requests.length === 0 && !pageLoading && (
        <div className={styles.warnText}>
          <CurrencyExchangeIcon className={styles.rotatingIcon} />
          <span>Добавьте новую заявку</span>
        </div>
      )}

      {requestLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(28, 107, 31, 0.7)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        selectedRequest &&
        !pageLoading && (
          <Request
            headers={requestOptions}
            item={selectedRequest}
            contract={contract}
            handleOpen={handleRequesttModalOpen}
            currencies={currencies}
            handleFileUpload={handleFileUpload}
            triggerFileInput={triggerFileInput}
            requestLoading={requestLoading}
            handleChangeUpdateInputs={handleChangeUpdateInputs}
            handleUpdateRequest={handleUpdateRequest}
            statusConfig={statusConfig}
            requestDocTypes={requestDocTypes}
            fileLoadingStatus={fileLoadingStatus}
            deleteFile={deleteFile}
            fileDeletionStatus={fileDeletionStatus}
          />
        )
      )}

      <MainModal open={newRequestModalOpen} onClose={handleRequestModalClose}>
        <div className={styles.modalheader}>
          <h2>Новая заявка</h2>
          <div onClick={handleRequestModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal
          options={newRequestOptions}
          handleChangeInputs={handleChangeInputs}
          currencies={currencies}
          newData={newRequestData}
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(38, 45, 137, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(38, 45, 137, 0.7)",
              },
            }}
            onClick={handleCreateRequest}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
      </MainModal>
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default RequestsPage;
