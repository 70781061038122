import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profiles: [],
  company: "",
  companyId: null,
  profileStatus: null,
  profileStatuses: [],
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    removeProfiles: (state, action) => {
      state.profiles = [];
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    removeCompanyId: (state, action) => {
      state.companyId = null;
    },
    addProfiles: (state, action) => {
      state.profiles = [...state.profiles, action.payload];
    },
    updateProfiles: (state, action) => {
      state.profiles = state.profiles.map((profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
    },
    setProfileStatus: (state, action) => {
      state.profileStatus = action.payload;
    },
    removeProfileStatus: (state, action) => {
      state.profileStatus = null;
    },
    setProfileStatuses: (state, action) => {
      state.profileStatuses = action.payload;
    },
  },
});

export const {
  setProfiles,
  removeProfiles,
  setCompany,
  setCompanyId,
  removeCompanyId,
  addProfiles,
  updateProfiles,
  setProfileStatus,
  removeProfileStatus,
  setProfileStatuses,
} = agentSlice.actions;

export default agentSlice.reducer;
