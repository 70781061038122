import DotsSpinner from "../Loaders/DotsSpinner/DotsSpinner";
import styles from "./ProfileInfo.module.scss";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  TextField,
} from "@mui/material";

const ProfileInfo = ({
  options,
  check,
  managers,
  managers_1,
  managers_2,
  data,
  handleChangeManager,
  handleChangeCheck,
  handleChangeFields,
  handleFillInn,
  handleFillBik,
  isModal,
  profileStatus,
  bikLoading,
  innLoading,
  innModalLoading,
  bikModalLoading,
}) => {
  const { firstCheck, secondCheck } = check;
  const { manager_1, manager_2 } = managers;

  return (
    <div className={styles.body}>
      {options.map((option, index) => (
        <div className={styles.element} key={index}>
          <div className={styles.name}>
            <span>{option.label}</span>
          </div>
          {option.name !== "comission" &&
            option.name !== "inn_company" &&
            option.name !== "bik_company" && (
              <div className={styles.field}>
                <TextField
                  label={option.label}
                  placeholder={option.placeholder}
                  color="warning"
                  sx={{
                    width: "100%",
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                  }}
                  value={data ? data[option.name] : ""}
                  onChange={(e) => handleChangeFields(e, option.name)}
                  type={option.type}
                  InputLabelProps={{
                    shrink: !!data[option.name],
                  }}
                  disabled={profileStatus?.id === 3 && true}
                  required={
                    option.name !== "site_company" &&
                    option.name !== "comment" &&
                    true
                  }
                />
              </div>
            )}
          {(option.name === "inn_company" || option.name === "bik_company") && (
            <div className={styles.fieldWithButton}>
              <TextField
                label={option.label}
                placeholder={option.placeholder}
                color="warning"
                sx={{
                  width: "calc(100% - 120px)",
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
                value={data ? data[option.name] : ""}
                onChange={(e) => handleChangeFields(e, option.name)}
                type={option.type}
                InputLabelProps={{
                  shrink: !!data[option.name],
                }}
                disabled={profileStatus?.id === 3 && true}
                required
              />
              <Button
                sx={{ marginLeft: "10px", height: "40px" }}
                disabled={profileStatus?.id === 3 && true}
                onClick={() =>
                  option.name === "inn_company"
                    ? handleFillInn(isModal)
                    : handleFillBik(isModal)
                }
              >
                {option.name === "inn_company" &&
                (isModal ? innModalLoading : innLoading) ? (
                  <DotsSpinner />
                ) : option.name === "bik_company" &&
                  (isModal ? bikModalLoading : bikLoading) ? (
                  <DotsSpinner />
                ) : (
                  "Заполнить"
                )}
              </Button>
            </div>
          )}
          {option.name === "comission" && (
            <div className={styles.fieldText}>{data[option.name]} %</div>
          )}
        </div>
      ))}

      <div className={styles.checkboxElement}>
        <div className={styles.name}>
          <span>Работаете с ИЛС</span>
        </div>
        <div className={styles.field}>
          <Checkbox
            color="warning"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            checked={firstCheck || false}
            onChange={(e) => handleChangeCheck(e, isModal)}
            name="firstCheck"
            disabled={profileStatus?.id === 3 && true}
          />
        </div>
      </div>
      {firstCheck && (
        <CheckBoxOption
          handleChangeManager={handleChangeManager}
          manager={manager_1}
          managers={managers_1}
          text="Менеджер ИЛС"
          managerKey="manager_1"
          isModal={isModal}
          profileStatus={profileStatus}
        />
      )}
      <div className={styles.checkboxElement}>
        <div className={styles.name}>
          <span>Работаете с ИЛС ТП</span>
        </div>
        <div className={styles.field}>
          <Checkbox
            color="warning"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            checked={secondCheck || false}
            name="secondCheck"
            onChange={(e) => handleChangeCheck(e, isModal)}
            disabled={profileStatus?.id === 3 && true}
          />
        </div>
      </div>
      {secondCheck && (
        <CheckBoxOption
          handleChangeManager={handleChangeManager}
          manager={manager_2}
          managers={managers_2}
          text="Менеджер ИЛС ТО"
          managerKey="manager_2"
          isModal={isModal}
          profileStatus={profileStatus}
        />
      )}
    </div>
  );
};

const CheckBoxOption = ({
  handleChangeManager,
  manager,
  managers,
  text,
  managerKey,
  isModal,
  profileStatus,
}) => {
  return (
    <>
      <div className={styles.element}>
        <div className={styles.checkboxName}></div>
        <div className={styles.field}>
          {managers.length > 0 && (
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 350, minWidth: 200 }}
            >
              <Autocomplete
                options={managers}
                getOptionLabel={(option) => option.full_name}
                value={managers.find((m) => m.id === manager) || null}
                onChange={(e, newValue) =>
                  handleChangeManager(
                    { target: { value: newValue?.id } },
                    managerKey,
                    isModal
                  )
                }
                disabled={profileStatus?.id === 3}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={text}
                    variant="standard"
                    color="warning"
                  />
                )}
                sx={{
                  maxHeight: 250,
                }}
              />
            </FormControl>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
